// import Vue from 'vue';
// import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import home from '@/views/home.vue'
import fccpc from '@/views/fccpc.vue'

// Vue.use(VueRouter);
const routes = [
  { path: '/', component: home },
  { path: '/fccpc', component: fccpc }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
// 配置路由
// const routes = [
//   { path: '/', component: home },
//   { path: '/fccpc', component: fccpc }
// ];

// // 创建VueRouter实例
// const router = new VueRouter({
//   routes
// });

export default router;