<template>
  <div class="box">
    <img src="../assets/fccpcImage.jpg" alt="">
  </div>
</template>
<script>
  export default {
    data(){
        return {

        }
    },
    methods :{

    }
  }
</script>
<style scoped>
.box {
  width: 80%;
  padding: 30px 10%;
  margin: auto;
  background: #fff;
}
.box img {
  width: 100%;
}
</style>
