function getScreenWidth () {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
function handleScreenWidthChange () {
  const screenWidth = getScreenWidth();
  const htmlElement = document.documentElement;
  htmlElement.style.fontSize = `${screenWidth / 10}px`;
}
handleScreenWidthChange();
window.addEventListener('resize', handleScreenWidthChange);
