<template>
  <div id="app">
    <!-- <HelloWorld /> -->
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './views/home.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>

</style>
