<template>
  <div id="home" class="box">
    <div class="header">
      <div id="Home" class="headerTop">
        <div class="headerTopCon">
          <div>
            <img src="../assets/headerTopData.png" alt="">
            <span>+234 9034352623</span>
          </div>
          <div>
            <img src="../assets/headerTopEmail.png" alt="">
            <span>support@prosperityarchipelago.cc</span>
          </div>
          <!-- <div>
            <img src="../assets/headerTopLocation.png" alt="">
            <span>8, LAKE CHAD CRESCENT, MAITAMA, FCT, NIGERIA</span>
          </div> -->
        </div>
      </div>
      <!-- 导航 -->
      <div :class="scroll > 40? 'fixed':''" class="headerNav">
        <div class="headerNavCon">
          <!-- logo -->

          <img class="logo" src="../assets/androidLogo.jpeg" alt="">
          <img class="logo" src="../assets/logo.png" alt="">
          <span class="titleName">Go Cash</span>
          <ul>
            <li><a :class="currentIndex == 0? 'show': ''" @click='tabClick(0)' href="#Home">Home</a></li>
            <li><a :class="currentIndex == 1? 'show': ''" @click='tabClick(1)' href="#CompanyProfile">Our Taeget</a></li>
            <li><a :class="currentIndex == 2? 'show': ''" @click='tabClick(2)' href="#OurProduct">Our Products</a></li>
            <!-- <li><a :class="currentIndex == 3? 'show': ''" @click='tabClick(3)' href="#OurPartners">Our Partners & Advisors</a></li> -->
            <!-- <li><a :class="currentIndex == 3? 'show': ''" href="https://www.imoney.one/item.html" >Terms of User</a></li> -->
            <!-- <li><a :class="currentIndex == 4? 'show': ''" href="https://www.imoney.one/privacy-policy.html">Privacy Policy</a></li> -->
            <li><a :class="currentIndex == 5? 'show': ''" @click='tabClick(5)' href="#ContactUs">Contact Us</a></li>
            <li><a href="https://www.prosperityarchipelago.cc/privacy-policy.html" target="_blank">Privacy Policy</a></li>
            <li><a href="https://www.prosperityarchipelago.cc/loan-terms.html" target="_blank">Loan Terms</a></li>
            <li><a @click="getfccpc">Fccpc License Plate</a></li>
          </ul>
        </div>
      </div>
      <!-- banner内容 -->
      <div class="bannerText">
        <p>Go Cash</p>
        <p>A good choice for your loan</p>
        <a href="https://apps.apple.com/ng/app/id6463715494" target="_blank"><img src="../assets/govash.png" alt=""></a>
      </div>
    </div>

    <!-- 公司介绍 -->
    <div id="CompanyProfile" class="story">
      <div class="financialHiFund">
        Go Cash is a financial loan service provided by PROSPERITY ARCHIPELAGO LTD. It is licensed and regulated by the Federal Competition &amp; Consumer Protection Commission (FCCPC) of Nigeria.
      </div>
      <div class="regulatedLicensed">
        Licensed and regulated by the FCCPC of Nigeria.
        <img src="../assets/nigeria.jpg" alt="">
      </div>
      <!-- <i class="triangle"></i> -->
      <div class="storyCon w">
        <h3>OUR TARGET</h3>
        <div>
          <span class="serial">1</span>
          <p>To Engage In And Carry Out Business As Money Lenders</p>
        </div>
        <div>
          <span class="serial">2</span>
          <p>To Engage In The Business Of Hire Purchase Of And Instalmental Payments For All MannerOf Goods Including Mobile Phones And Mobile Phones Accessories, Computers And Computer Accessories, And Other Mobile And Computer Devices; Furniture, Chairs, Rugs And Carpets,Televisions, Radio Players, Fans, Refrigerators And Other Household Items; Cars, Trucks, AndOther Motor Vehicles; Clothes, Shoes, Belts, Jewelries, And Other Clothing And BeautyProducts, As Well As All Manner Of Services</p>
        </div>
        <div>
          <span class="serial">3</span>
          <p>siness loans offered by various financial institutions. This platform is highly interactive and easy to use to get different offers from different financial institutions.</p>
        </div>
        <div>
          <span  class="serial">4</span>
          <p>To Carry On The Business Of Software Designing, Development, Customisation,Implementation Maintenance, Testing And Benchmarking, Designing, Developing And DealingIn Computer Software And Solutions, And To Import, Export, Sell, Purchase, Distribute, Host(In Data Centres Or Over The Web) Or Otherwise Deal In Own And Third Party ComputerSoftware Packages, Programs And Solutions, And To Provide Internet / Web BasedApplications, Services And Solutions, Provide Or Take Up Information Technology RelatedAssignments On Sub-contracting Basis, Offering Services On-site/ Offsite Or ThroughDevelopment Centres Using Owned /Hired Or Third Party Infrastructure And Equipment</p>
        </div>
      </div>
    </div>

    <!-- 产品 -->
    <div id="OurProduct" class="product">
      <!-- <i class="triangle"></i> -->
      <div class="productCon w">
        <h3>OUR PRODUCTS</h3>
        <div class="content">
          <div class="contentLeft">
            <h5>Loan product information.</h5>
            <p> - Loan amount: NGN 5,000 - NGN 100,000</p>
            <p>- Loan Term: Minimum 91 days, Maximum 180 days</p>
            <p>- Annual Percentage Rate (APR): up to 14% per annum (APR)</p>
            <p>- Daily interest rate: 14%/365 = 0.0384%</p>
            <p>- Service charge, administration fee and others: NGN 0</p>
          </div>
          <div class="contentRight">
            <img src="../assets/productImg01.png" alt="">
          </div>
          <!-- <img src="../assets/productImg02.png" alt=""> -->
        </div>
        <div class="content margginTp">
          <div class="contentRight marggin">
            <img src="../assets/productImg02.png" alt="">
          </div>
          <div class="contentLeft">
            <p>For example, if you apply for a loan of NGN 5,000 for 180 days, the amount to be paid would be NGN 5,000 loan + NGN 5,000 interest * 0.0384% * 180 days = NGN 5,345</p>
            <p>Total monthly payment: NGN 890.3 = NGN 5,345 / 6 months</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <!-- <i class="triangle"></i> -->
    <!-- <div id="OurPartners" class="extended">
      <div class="extendedCon w">
        <h3>OUR EXTENDED FAMILY</h3>
        <ul>
          <li>
            <img src="../assets/extended01.png" alt="">
            <img src="../assets/extended02.png" alt="">
            <img src="../assets/extended03.png" alt="">
            <img src="../assets/extended04.png" alt="">
            <img src="../assets/extended05.png" alt="">
            <img src="../assets/extended06.png" alt="">
            <img src="../assets/extended07.png" alt="">
            <img src="../assets/extended08.png" alt="">
            <img src="../assets/extended09.png" alt="">
            <img src="../assets/extended10.png" alt="">
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 公司名称：PROSPERITY ARCHIPELAGO LTD   邮箱：support@prosperityarchipelago.cc  电话：+234 9034352623
    把一个网站的信息改成这些
    17:17
    地址：8, LAKE CHAD CRESCENT, MAITAMA, FCT, NIGERIA -->
    <!-- 地址 -->
    <div id="ContactUs" class="place">
      <div class="placeCon w">
        <h3>CONTACT US</h3>
        <div class="placeContent">
          <div class="conLeft">
            <h4>PROSPERITY ARCHIPELAGO LTD</h4>
            <!-- <div>
              <p>Head Office</p>
              <span>8, LAKE CHAD CRESCENT, MAITAMA, FCT, NIGERIA</span>
            </div> -->
            <div>
              <p>Mailbox</p>
              <span>support@prosperityarchipelago.cc</span>
            </div>
            <div>
              <p>Phone</p>
              <span>+234 9034352623</span>
            </div>

          </div>
          <div class="map">
            <img src="../assets/map.jpeg" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div class="footerCon w">
        <p>© 2022 PROSPERITY ARCHIPELAGO LTD, All rights reserved.</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
        return {
            currentIndex:0,
            done : true,
            scroll : "",
        }
    },
    methods :{
      tabClick(index){
        let _this = this
        this.done = false
        this.currentIndex = index;
        setTimeout(() => {
            _this.done = true
          }, 1500);
      },
      getfccpc() {
        this.$router.push('/fccpc')
      },
      //滚动事件
      handleScroll() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        console.log('12312',scrollTop);
        this.scroll = scrollTop
        var offsetTop1 = document.querySelector('#Home').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
        var offsetTop2 = document.querySelector('#CompanyProfile').offsetTop
        var offsetTop3 = document.querySelector('#OurProduct').offsetTop
        // var offsetTop4 = document.querySelector('#OurPartners').offsetTop
        var offsetTop5 = document.querySelector('#ContactUs').offsetTop
        console.log('11111',offsetTop1)
        console.log('22222',offsetTop2)
        console.log('33333',offsetTop3)
        // console.log('44444',offsetTop4)
        console.log('55555',offsetTop5)
        if(this.done){
          if (scrollTop >= offsetTop1 && scrollTop < offsetTop2) {
            this.currentIndex = 0
          } else  if(scrollTop >= offsetTop2 && scrollTop < offsetTop3-100){
            this.currentIndex = 1
          }else if(scrollTop >= offsetTop3-100 && scrollTop < offsetTop5 -300){
            this.currentIndex = 2
          }
          // else if(scrollTop >= offsetTop4 -100 && scrollTop < offsetTop5){
          //   this.currentIndex = 3
          // }
          else if(scrollTop >= offsetTop5-1000) {
            this.currentIndex = 5
          }
        }
      },
    },
    mounted() {
  	// 向页面添加滚动事件
    window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
      window.removeEventListener('scroll', this.handleScroll)
    },
  }
</script>

<style scoped>
@import "../styles/home.css";
</style>

